import type { PropsWithChildren } from "react";
import React from "react";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { useAxiosInterceptor } from "@/src/core/AxiosFetcher";
import { MediaQueryProvider } from "@/src/hooks/useMedia";
import "@/src/stores/createStore";
import { persistor, store } from "@/src/stores/createStore";
import type { RootState } from "@/src/stores/rootReducer";

const ReduxProviderWrapper: React.FC<PropsWithChildren<{ preloadedState?: Partial<RootState> }>> = ({ children }) => {
  useAxiosInterceptor(store);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <MediaQueryProvider>
          <div>{children}</div>
        </MediaQueryProvider>
      </PersistGate>
    </Provider>
  );
};

ReduxProviderWrapper.displayName = "ReduxProviderWrapper";

export default ReduxProviderWrapper;
